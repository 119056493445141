<script>
import lottie from 'lottie-web';
import animationData from '@/assets/animations/home1.json';
import animationDataMobile from '@/assets/animations/homemobile.json';

export default {
  name: 'faq',

  mounted() {
    const params = {
      container: this.$refs.bg,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    };

    lottie.loadAnimation(params);

    params.container = this.$refs.bgmobile;
    params.animationData = animationDataMobile;

    lottie.loadAnimation(params);
  },
};

</script>

<template>
  <div class="faq wrap">
    <the-nav>
      <router-link active-class="is-active" :to="{ name: 'jobseekers' }">
        Looking for a job?
      </router-link>
    </the-nav>

    <header class="faq_header">

      <h1 class="title is-2">jobseekers</h1>
      <h2>Got a question about the Heroes Jobs app?<br>We got you.</h2>
      <img class="faq_conclusion_img" src="@/assets/img/emojis/hero.png" alt="">

      <div class="bg" ref="bg"></div>
      <div class="bgmobile" ref="bgmobile"></div>
    </header>

    <body>

      <div class="faq_synopsis">
        <ul>
          <li class="faq_synopsis_intralink">
            <a href="#q1">How does the Heroes Jobs app work?</a>
          </li>
          <li class="faq_synopsis_intralink">
            <a href="#q2">How old do I need to be to apply for jobs?</a>
          </li>
          <li class="faq_synopsis_intralink">
            <a href="#q3">Why are there no job postings around me?</a>
          </li>
          <li class="faq_synopsis_intralink">
            <a href="#q4">Why are all the companies around me listed as "hiring soon"?</a>
          </li>
          <li class="faq_synopsis_intralink">
            <a href="#q5">Do I have to complete my entire profile to apply for jobs?</a>
          </li>
          <li class="faq_synopsis_intralink">
            <a href="#q6">What if I mess up my videos or want to re-do them?</a>
          </li>
          <li class="faq_synopsis_intralink">
            <a href="#q7">How does Heroes guarantee a fast response to applicants?</a>
          </li>
        </ul>
      </div>

      <div class="faq_questions">
        <ul>
          <li class="faq_questions_item" id="q1">
            <h3 class="faq_questions_item_title">
              <img class="faq_questions_item_title_img" src="@/assets/img/emojis/phone.png" alt="">
              How does the Heroes Jobs app work?
            </h3>
            <p>The greatest benefit to the Heroes Jobs app is being able to manage your interview and application process once you’ve been invited to the Heroes Jobs app by an employer.</p>
            <p>You'll be able to:</p>
            <ul class="faq_questions_item_sublist">
              <li>Fill out a quick profile with only the most vital info (no extensive questionnaires).</li>
              <li>Record 4 mini videos with easy questions to showcase your personality.</li>
              <li>Schedule your interviews at a time that works best for you.</li>
              <li>Chat with the hiring manager beforehand if you have any questions.</li>
            </ul>
            <p>Our app also allows you to have video interviews which is more practical for everyone (and safer in the light of the current situation with COVID-19). 😷</p>
            <p>You'll then receive a message stating whether you've been accepted for the job or not and can proceed from there.</p>
            <p>We're undergoing a lot of exciting changes and growing fast. 🚀 We're chugging coffee and pounding keys to expand the hiring companies in our network and connect jobseekers with their favorite brands in a whole new way!</p>
          </li>

          <li class="faq_questions_item" id="q2">
            <h3 class="faq_questions_item_title">
              <img class="faq_questions_item_title_img" src="@/assets/img/emojis/calendar.png" alt="">
              How old do I need to be to apply for jobs?
            </h3>
            <p>Most of the companies in our network require applicants to be at least 16 to apply, but some require you to be 18 or older. If you're below the age limit of that company's requirement, your applications will be rejected by the company.</p>
            <p>We're sorry that we have no influence over company age requirements.</p>
          </li>

          <li class="faq_questions_item" id="q3">
            <h3 class="faq_questions_item_title">
              <img class="faq_questions_item_title_img" src="@/assets/img/emojis/magnifyingglass.png" alt="">
              Why are there no job postings around me?
            </h3>
            <p>The Heroes Jobs app is a helpful communication and management tool that improves an ongoing application process.</p>
            <p>For example, you've already applied for a job and the company invites you to download the Heroes Jobs app so that you can more easily book your interview time, chat with the manager beforehand, and have your interview via video.</p>
            <p>You can also add info to your profile like mini intro videos that showcase your personality, giving the manager a better impression of who you are before your interview.</p>
            <p>In some cities, it's possible to search for other jobs in the app, but this feature isn't yet available throughout the entire continental U.S.</p>
            <p>We can assure you that we're expanding our network to various hiring locations near you as fast as we can. So please check with us in the future.</p>
          </li>

          <li class="faq_questions_item" id="q4">
            <h3 class="faq_questions_item_title">
              <img class="faq_questions_item_title_img" src="@/assets/img/emojis/hourglass.png" alt="">
              Why are all the companies around me listed as "hiring soon"?
            </h3>
            <p>This means that the companies are in our network but they don't currently have open positions. If you check back periodically, new positions may become available. Once a company posts a job on our app, it appears automatically.</p>
            <p>You can set up notifications to receive an alert when a position opens at that company.</p>
          </li>

          <li class="faq_questions_item" id="q5">
            <h3 class="faq_questions_item_title">
              <img class="faq_questions_item_title_img" src="@/assets/img/emojis/memo.png" alt="">
              Do I have to complete my entire profile to apply for jobs?
            </h3>
            <p>Some companies only require certain details but some prefer that you have a full profile which includes your personal details, 4 mini videos, your work schedule availability, etc.</p>
            <p>In any case, filling out your entire profile with all the details is a fantastic way to present yourself to employers and it'll improve your chances of getting hired.</p>
            <p>It'll also make it easy to apply for jobs in one tap if your profile is complete and you don't have to provide details on the spot.</p>
          </li>

          <li class="faq_questions_item" id="q6">
            <h3 class="faq_questions_item_title">
              <img class="faq_questions_item_title_img" src="@/assets/img/emojis/camera.png" alt="">
              What if I mess up my videos or want to re-do them?
            </h3>
            <p>You can! Just re-record them and they'll be replaced with your newest video. You cannot go back, though. If you record over a video, it's lost forever.</p>
          </li>

          <li class="faq_questions_item" id="q7">
            <h3 class="faq_questions_item_title">
              <img class="faq_questions_item_title_img" src="@/assets/img/emojis/thunderbolt.png" alt="">
              How does Heroes guarantee a fast response to applicants?
            </h3>
            <p>Applications expire nine days after they’re submitted, so hiring managers have limited time to respond. This gives them incentive to respond ASAP or they could lose opportunities to hire talented jobseekers like yourself.</p>
          </li>

        </ul>
      </div>

      <div class="faq_conclusion">
        <img class="faq_conclusion_img" src="@/assets/img/emojis/raised_hand.png" alt="">
        <p>Do you have another question?</p>
        <p>You can send us a message directly in the app or <a href="mailto:hello@heroes.jobs">email us</a>.</p>
      </div>

    </body>
  </div>
</template>


<style lang="scss" scoped>
.faq {

  &_conclusion {
    margin-top: 70px;
    text-align: center;

    &_img {
      height: 30px;
      width: 30px;
    }
  }

  &_synopsis {
    &_intralink {
      margin-bottom: 5px;
    }
  }

  &_header {
    text-align: center;
    margin-top: 90px;
    margin-bottom: 90px;
  };

  h2 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 24px;
  };

  &_questions {

    margin-top: 50px;

    &_item {

      &_title {
        font-weight: 700;
        font-size: 22px;
        padding-top: 30px;
        padding-bottom: 20px;

        &_img {
          height: 26px;
          width: 26px;
          margin: 0 10px -5px 0;
        };
      };

      &_sublist {
        margin: 0 40px 20px 40px;
        list-style-type: circle;
      };
    };
  };

  p{
    margin-bottom: 10px;
  };
};

</style>
